import {MetricService} from "./MetricService";

export class metricMarketingUtils {

    getTotalMetricNewsLetter = async (signal: AbortSignal, metric: string, Marketing: any): Promise<any> => {

        const groupKey = Marketing.params.filter_filters.group as any;
    
        let modifiedFilters = {
          filter_filters: {
            between_dates: {
              from: Marketing.params.filter_filters.between_dates.from,
              to: Marketing.params.filter_filters.between_dates.to,
            },
            group: Marketing.params.filter_filters.group,
            // VERIFY IF GROUP IS THERAPEUTIC AREAS, IF TRUE, ADD THERAPEUTIC AREAS IDS TO FILTERS
            ...(groupKey === 'therapeutic_areas' ? { therapeutic_areas_ids: Marketing.params.filter_filters['therapeutic_areas_ids'] } : {
              [groupKey]: Marketing.params.filter_filters[groupKey as keyof typeof Marketing.params.filter_filters]
            }),
          },
        };
    
        // const metricService = new MetricService();
        // const response = await metricService.getMetricEndpointData(modifiedFilters, metric, 'group', signal);
        // const responseData = response.getResponseData();
        // if (responseData.success === false) {
        //   return 0;
        // }
        return Math.floor(Math.random() * 100);
    }

    getTotalMetriConversion = async (signal: AbortSignal, metric: string, Marketing: any): Promise<any> => {

      const groupKey = Marketing.params.filter_filters.group as any;
  
      let modifiedFilters = {
        filter_filters: {
          between_dates: {
            from: Marketing.params.filter_filters.between_dates.from,
            to: Marketing.params.filter_filters.between_dates.to,
          },
          group: Marketing.params.filter_filters.group,
          // VERIFY IF GROUP IS THERAPEUTIC AREAS, IF TRUE, ADD THERAPEUTIC AREAS IDS TO FILTERS
          ...(groupKey === 'therapeutic_areas' ? { therapeutic_areas: Marketing.params.filter_filters['therapeutic_areas_ids'] } : {
            [groupKey]: Marketing.params.filter_filters[groupKey as keyof typeof Marketing.params.filter_filters]
          }),
        },
      };
  
      // const metricService = new MetricService();
      // const response = await metricService.getMetricEndpointData(modifiedFilters, metric, 'group', signal);
      // const responseData = response.getResponseData();
      // if (responseData.success === false) {
      //   return 0;
      // }
      return Math.floor(Math.random() * 100);
  }

    getTotalMetricWebsiteMain = async (signal: AbortSignal, metric: string, Marketing: any, subGroup: any): Promise<any> => {

        const groupKey = Marketing.params.filter_filters.group as any;
    
        let modifiedFilters = {
          filter_filters: {
            between_dates: {
              from: Marketing.params.filter_filters.between_dates.from,
              to: Marketing.params.filter_filters.between_dates.to,
            },
            group: subGroup !== null && !Array.isArray(subGroup) ? subGroup : Marketing.params.filter_filters.selected_group, // IF SUBGROUP IS NOT NULL, UPDATE FILTERS
            // VERIFY IF GROUP IS THERAPEUTIC AREAS, IF TRUE, ADD THERAPEUTIC AREAS IDS TO FILTERS
            ...(groupKey === 'therapeutic_areas' ? { therapeutic_areas: Marketing.params.filter_filters['therapeutic_areas_ids'] } : {
              [groupKey]: Marketing.params.filter_filters[groupKey as keyof typeof Marketing.params.filter_filters]
            }),
          },
        };
    
        const metricService = new MetricService();
        const response = await metricService.getMetricEndpointData(modifiedFilters, metric, 'group', signal);
        const responseData = response.getResponseData();
        if (responseData == null || responseData.success === false) {
          return [];
        }
    
        return responseData.data;
      };

      getTotalMetricWebsiteExtended = async (signal: AbortSignal, valueToGet: any, Marketing: any, extendedMetrics: any, subGroup: any ): Promise<any> => {

        const groupKey = Marketing.params.filter_filters.group as any;
    
        let modifiedFilters = {
          filter_filters: {
            between_dates: {
              from: Marketing.params.filter_filters.between_dates.from,
              to: Marketing.params.filter_filters.between_dates.to,
            },
            group: subGroup !== null ? subGroup : Marketing.params.filter_filters.selected_group,
            ...(groupKey === 'therapeutic_areas' ? { therapeutic_areas: [valueToGet] } : {
              [groupKey]: [valueToGet]
            })
    
          },
        };
    
        const metricService = new MetricService();
        const response = await metricService.getMetricEndpointData(modifiedFilters, extendedMetrics, 'group', signal);
        const responseData = response.getResponseData();
    
        if (responseData == null || responseData.success === false) {
          return [];
        }
    
        return responseData.data;
      };
};