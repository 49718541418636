
import { Metric } from 'app/models/metrics/Metric';
import { MetricService } from 'app/services/metric/MetricService';
import { FC, useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '_metronic/layout/core'
import { ProgrammeExplorerCard } from '../../components/ProgrammeExplorerCard';
import { useProgrammeExplorer } from '../../ProgrammeExplorerProvider';

const ProgrammeExplorerImpactWrapper: FC = () => {
    const [showMetrics, setShowMetrics] = useState<boolean>(false);
    const [allowedMetrics, setAllowedMetrics] = useState<Metric[]>([]);

    const ProgrammeExplorer = useProgrammeExplorer();

    const toggleMetricsShow = () => {
        let metricsShowStatus = false
        const { programmes, campaigns, between_dates, connects } = ProgrammeExplorer.params.filter_filters;
        if ( 
            (programmes !== undefined && programmes.length > 0)
            || (campaigns !== undefined && campaigns.length > 0)
          ) {
            metricsShowStatus = true
          } else if (
            (between_dates !== undefined && between_dates?.from !== undefined && between_dates?.to !== undefined)
          ) {
            metricsShowStatus = true
          } else if (
            (connects !== undefined && connects.length > 0)
          ) {
            metricsShowStatus = true
        }
        setShowMetrics(metricsShowStatus);
    }

    const getAllowedMetrics = async () => {
        const response = await (await ( (new MetricService()).getAllowedMetricsByCategory('impact', true))).getResponseData();
  
        if (response.success === false) {
            console.error("Error getting allowed metrics", response);
            toastr.error("Error getting allowed metrics");
        }
  
        setAllowedMetrics(response.data);
    }

    useEffect(() => {
        getAllowedMetrics();
    }, [])

    useEffect(() => {
        toggleMetricsShow();
        console.log("Filter has changed", ProgrammeExplorer.params.filter_filters);
    }, [ProgrammeExplorer.params.filter_filters])


    return (
        <div className='row'>
            {
                !showMetrics ?
                    (<h3 className={'text-muted text-center'}>Por favor elija un modo para visulizar los datos</h3>)
                    : allowedMetrics.map((metric) => {
                        return ProgrammeExplorer.getMetricProgrammeExplorerCard(metric, ProgrammeExplorer.params) 
                    })
            }

        </div>

    )
}

export { ProgrammeExplorerImpactWrapper }
