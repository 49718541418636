import {useMarketing} from '../../MarketingProvider'
import { useCallback, useEffect, useState } from 'react';
import MarketingTableCustom from '../../components/MarketingTableCustom';
import { formatNumber, limitDecimals, replaceUnderscoresWithSpacesAndCapitalize } from 'app/utils/utils';
import SpinnerCustom from '@components/SpinnerCustom';
import { metricMarketingUtils } from 'app/services/metric/MetricMarketingUtils';
import CustomSelect from '@components/form/CustomSelect';
import { SingleValue } from 'react-select';

const MarketingNavigationWrapper = () => {

  //PROVIDERS

  const Marketing = useMarketing();

  // STATES

  const [data, setData] = useState<any>(null);
  const [oldData, setOldData] = useState<any>(null);
  const [option, setOption] = useState('');
  const [subGroup, setSubGroup] = useState<any>([]);

  // OPTIONS FOR SELECTS

  const technologyOptions = [ { value: 'device_category', label: 'Device category' }, { value: 'browser', label: 'Browser' }, { value: 'operating_system', label: 'Operating system' }, { value: 'platform', label: 'Platform' } ];
  const demographicsOptions = [ { value: 'region' , label: 'GEO - Region'}, { value: 'country' , label: 'GEO - Country'}, { value: 'language' , label: 'Language'} ]  
  const options = [{value: Marketing.params.filter_filters.group , label: `Insight level selected: ${replaceUnderscoresWithSpacesAndCapitalize(Marketing.params.filter_filters.group || '')}`}, { value: 'channel', label: 'Channel' }, { value: 'sources', label: 'Source' }, 
  { value: 'medium', label: 'Medium' }, { value: 'demographics', label: 'Demographics' }, { value: 'technology', label: 'Technology' } ];
  

  // ABORT CONTROLLERS

  const abortControllers = {
    entrances: new AbortController(),
    exits : new AbortController(),
  };

  // FUNCTION TO CHANGE SELECTED OPTION

  const onChangeOption = (selected: SingleValue<unknown>) => {
    let optionSelected: any = selected;
    setSubGroup(null);

    if (optionSelected.value !== 'technology' || optionSelected.value !== 'demographics') { // IF SELECTED OPTION IS NOT TECHNOLOGY OR DEMOGRAPHICS, UPDATE FILTERS
      Marketing.updateFilters({ ...Marketing.params.filter_filters, selected_group: optionSelected.value});
    }
    setOption(optionSelected.value);
  }


  const getMetricsData = async () => {
    let group = Marketing.params.filter_filters.group; // GET GROUP

    if (group === 'therapeutic_areas') {
      group = 'therapeutic_areas_ids';
    }

    const marketingUtils = new metricMarketingUtils();

    // GET DATA FOR EACH METRIC

    const metricsArray = await Promise.all([
      marketingUtils.getTotalMetricWebsiteMain(abortControllers.entrances.signal, 'entrance', Marketing, subGroup),
      marketingUtils.getTotalMetricWebsiteMain(abortControllers.exits.signal, 'exit', Marketing, subGroup),
    ]).then((values) => ({
      entrancesData: values[0],
      exitsData: values[1],
    }));

    const uniqueGroups = Array.from(
      new Set(
        (await Promise.all(
          Object.values(metricsArray).flatMap(async (metricPromise: any) => {
            const metricData = await metricPromise;
            return metricData.map((element: any) => element.group);
          })
        )).flat().filter(Boolean)
      )
    );
  
    let arrayColumns = Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group ?
    Marketing.params.filter_filters.selected_names : 
    uniqueGroups; // GET ARRAY OF SELECTED VALUES

    const data = Array.isArray(arrayColumns) ?  // IF ARRAY, RETURN DATA FOR EACH VALUE
      await Promise.all(arrayColumns.map(async (column: any, index: number) => {

        let entrances = limitDecimals(metricsArray.entrancesData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.entrancesData.find((element: any) => element.group === column).total);
        let exits = limitDecimals(metricsArray.exitsData.find((element: any) => element.group === column)?.total == null ? 0 : metricsArray.exitsData.find((element: any) => element.group === column).total);

        // IF DATA IS NULL, RETURN 0 AND IF CONTAINS DECIMALS, LIMIT TO 3 DECIMALS
        if ( Marketing.params.filter_filters.group === 'overall' && Marketing.params.filter_filters.selected_group === 'overall' ) {
          entrances = limitDecimals(metricsArray.entrancesData[0]?.total == null ? 0 : metricsArray.entrancesData[0].total);
          exits = limitDecimals(metricsArray.exitsData[0]?.total == null ? 0 : metricsArray.exitsData[0].total);
        }

        if ( Marketing.params.filter_filters.group == 'formats' && Marketing.params.filter_filters.selected_group == 'formats' ) {
          let formatColumn = column.toLowerCase().replace(' ', '-').trim();
          entrances = limitDecimals(metricsArray.entrancesData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.entrancesData.find((element: any) => element.group === formatColumn).total);;
          exits = limitDecimals(metricsArray.exitsData.find((element: any) => element.group === formatColumn)?.total == null ? 0 : metricsArray.exitsData.find((element: any) => element.group === formatColumn).total);
        }

        let insight = Marketing.params.filter_filters.selected_group == Marketing.params.filter_filters.group ?
        Marketing.params.filter_filters.selected_names.find((element: any) => element === column) :
        column == "" || column == null ? "Other" : column;

          return {
            insight: insight,
            entrances,
            exits,
          };
        }))
      : []; // IF NOT ARRAY, RETURN EMPTY ARRAY
  
    return data;
  };

  useEffect(() => {

    if (Marketing.params.filter_filters.group === '') return
    if (subGroup === null && (Marketing.params.filter_filters.selected_group === 'technology' || Marketing.params.filter_filters.selected_group === 'demographics')) return

    setOldData(data); // SET OLD DATA

    const fetchData = async () => {
      const data = await getMetricsData();
      setData(data); // SET DATA
    };

    fetchData(); // FETCH DATA

    // ABORT CONTROLLERS

    return () => {
      Object.values(abortControllers).forEach(controller => controller.abort());
    };



  }, [Marketing.params.filter_filters, subGroup]);

  const calculateTotals = (data: any) => {

    let totals = {
      totalEntrances: 0,
      totalExits: 0,
    };

    data && data.forEach((element: any) => {
      totals.totalEntrances += parseFloat(element.entrances);
      totals.totalExits += parseFloat(element.exits);
    });

    return totals;
  };  

  // FUNCTION TO GENERATE SELECTS DEPENDING ON SELECTED OPTION
  
  const renderSelects = useCallback(() => {
    switch (option) {
        case 'demographics':
          return (
            <div className='form-group fs-12p d-flex justify-content-center mt-2 ms-2' key={subGroup}>
              <CustomSelect options={demographicsOptions} placeholder="Select Option" customFontSize="12px" onChangeSingle={(selected: SingleValue<unknown>) => {
                  let optionSelected: any = selected;
                  setSubGroup(optionSelected.value);
                }} minWidth="200px" defaultValue={{label:demographicsOptions.find((option) => option.value === subGroup)?.label, value: subGroup}}/>
            </div>
          )
        case 'technology':
            return (
              <div className='form-group fs-12p d-flex justify-content-center mt-2 ms-2' key={subGroup}>
                <CustomSelect options={technologyOptions} placeholder="Select Option" customFontSize="12px" onChangeSingle={(selected: SingleValue<unknown>) => {
                  let optionSelected: any = selected;
                  setSubGroup(optionSelected.value);
                }} minWidth="200px" defaultValue={{label:technologyOptions.find((option) => option.value === subGroup)?.label, value: subGroup}}/>
              </div>
            )
        default:
            return (
                <></>
            )
    }
}, [option, subGroup])

  return (
    <>
      {Marketing.params.filter_filters.group !== '' ? 
        Marketing.params.filter_filters.selected_names.length > 0 ? (
          <>
        <div className='col-xl-12 position-relative'>
          {data ?  (
          <>
          <div className='bg-primary text-center fw-bolder text-light p-2 fs-4' style={{borderTopLeftRadius : '5px' ,borderTopRightRadius : '5px'}}>NEWSLETTER KPIs</div>
          <div className="p-1" style={{backgroundColor: '#33B4E5'}}></div>
          <MarketingTableCustom
            className='card-primary'
            title='Third-party data'
            subtitle='List of third-party data'
            key={Marketing.params.filter_filters.group}
            showTotalsColumn={true}
            totalsRow={calculateTotals(data)}
            totalsRowPosition='bottom'
            data={data}
            columns={[
              {
                name: `Insight level selected\n ${replaceUnderscoresWithSpacesAndCapitalize(
                  Marketing.params.filter_filters.group || ''
                )}`,
                keyValue: 'insight',
                className: 'text-center bg-marketing-fields-init pre-line fs-12p min-w-200px',
                renderHeader: () => {
                  return (
                    <div id={option}>
                      <div className='form-group fs-12p d-flex justify-content-center ms-2' style={{overflow: 'visible'}}>
                          <CustomSelect options={options} placeholder={`Select Option`} customFontSize="12px" onChangeSingle={onChangeOption} 
                          defaultValue={
                            {
                              label: options.find((option) => option.value === Marketing.params.filter_filters.selected_group)?.label,
                              value: Marketing.params.filter_filters.selected_group,
                            }
                          } minWidth="200px"/>
                      </div>
                      {renderSelects()}
                    </div>
                  );
                },
                render: (element: any) => {
                  return (
                    <>
                      <div className="text-center fw-bold">
                        <span>{element.insight}</span>
                      </div>
                    </>
                  );
                }
              },
              {
                name: 'Entrances',
                keyValue: 'entrances',
                className: 'align-middle text-center bg-marketing-fields min-w-200px',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.entrances))}</span>
                      </div>
                    </>
                  )
                },
              },
              {
                name: 'Exits',
                keyValue: 'exits',
                className: 'align-middle text-center bg-marketing-fields min-w-200px',
                render: (element: any) => {
                  return (
                    <>
                      <div className='text-center'>
                        <span>{formatNumber(limitDecimals(element.exits))}</span>
                      </div>
                    </>
                  )
                },
              },
            ]}
          />
          </>
      ) : <></>}
          <div className={data == oldData ? 'custom-overlay' : 'd-none'}>
            <SpinnerCustom className='mt-custom' size='medium'/>
          </div>
        </div>
        </>
      ) : (
        <h3 className={'text-muted text-center'}>Please select one or more {`${replaceUnderscoresWithSpacesAndCapitalize(Marketing.params.filter_filters.group)}`}</h3>
      ) : (
        <h3 className={'text-muted text-center'}>Por favor seleccione un modo</h3>
      )}
    </>
  )
}

export default MarketingNavigationWrapper
