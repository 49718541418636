import { FC, useEffect, useState } from 'react'
import { useProgrammeExplorerSupporter } from '../ProgrammeExplorerSupporterProvider'
import { ProgrammeExplorerSupporterCard } from './ProgrammeExplorerSupporterCard';
import './ProgrammeExplorerCard.css'

const ProgrammeExplorerBase: FC = () => {
  const ProgrammeExplorer = useProgrammeExplorerSupporter();
  const [wavesOptions, setWaveOptions] = useState<number[] | undefined>(undefined)
  const [showMetrics, setShowMetrics] = useState<boolean>(false);

  const toggleMetricsShow = () => {
    let metricsShowStatus = false
    const { programmes, campaigns, between_dates, connects } = ProgrammeExplorer.params.filter_filters;
    setWaveOptions(campaigns)
    if ( 
      (programmes !== undefined && programmes.length > 0)
      || (campaigns !== undefined && campaigns.length > 0)
    ) {
      metricsShowStatus = true
    } else if (
      (between_dates !== undefined && between_dates?.from !== undefined && between_dates?.to !== undefined)
    ) {
      metricsShowStatus = true
    } else if (
      (connects !== undefined && connects.length > 0)
    ) {
      metricsShowStatus = true
    }
    setShowMetrics(metricsShowStatus);
  }

  const getProgrammesClassName = (searchValues: string[]) => {
    const filterProgrammes = ProgrammeExplorer.params.filter_filters.programmes;

    // IF the target is project, check if the selected programmes match the search values
  
    if (filterProgrammes && filterProgrammes.length > 0) {    
      
      // Check if any of the selected programmes match the search values
      const anyProgramMatches = filterProgrammes.some(filterId => {
        const foundProgram = ProgrammeExplorer.programmes.find(program => program.id === filterId); // Find the programme by id
        return foundProgram && foundProgram.type ? searchValues.includes(foundProgram.type.toLowerCase()) : false; // Check if the programme type is in the search values
      });
    
      return anyProgramMatches ? '' : 'd-none';
    }

    // If the target is different from project, returns '''.
  
    return '';
  };


  useEffect(() => {
    toggleMetricsShow();
    console.log("Filter has changed", ProgrammeExplorer);
  }, [ProgrammeExplorer.params.filter_filters])

  return (
    <>
      {
        !showMetrics ?
          (<h3 className={'text-muted text-center'}>Selecciona un modo para visualizar los datos</h3>)
          : (
            <><div className='row'>
              <div className='col-xl-12'>
                <div className='title-supporter'>Impact (Moore's levels*)</div>
                <div className='subtitle-supporter'>What do HCPs think about the programme?<span className='sign-title'> *</span></div>
                <div className='cardcmewrap'>
                  <ProgrammeExplorerSupporterCard
                    key={'1'}
                    className='card cardcme programme-explorer-card-orange height-impact '
                    image='/media/svg/programme_explorer_supporter/medical-stethoscope-variant.svg'
                    title=''
                    level={'4'}
                    filters={ProgrammeExplorer.params}
                    metrics={[{ humanName: 'of HCPs agree they intend to change ther clinical practice as a result of this programme', name: 'practice_changing'}]}
                    symbol='%'
                    cardColor='orange'
                    option='impact'
                    wave={wavesOptions && wavesOptions?.length > 0 ? false : true}
                  />


                  <ProgrammeExplorerSupporterCard
                    key={'2'}
                    className='card cardcme height-impact'
                    image="/media/svg/programme_explorer_supporter/diamond.svg"
                    title=''
                    level={'2'}
                    filters={ProgrammeExplorer.params}
                    metrics={[{ humanName: 'of HCPs agree this programme is valuable', name: 'valuable'}]}
                    symbol='%'
                    cardColor='white'
                    option='impact'
                    wave={wavesOptions && wavesOptions?.length > 0 ? false : true} 
                  />

                  <ProgrammeExplorerSupporterCard
                    key={'3'}
                    className='card cardcme height-impact'
                    image="/media/svg/programme_explorer_supporter/idea.svg"
                    title=''
                    level={'3A'}
                    filters={ProgrammeExplorer.params}
                    metrics={[{ humanName: 'of HCPs agree this programme has improved their knowledge', name: 'knowledge_improving' }]}
                    symbol='%'
                    cardColor='white'
                    option='impact'
                    wave={wavesOptions && wavesOptions?.length > 0 ? false : true} 
                  />


                  <ProgrammeExplorerSupporterCard
                    key={'4'}
                    className='card cardcme height-impact'
                    image="/media/svg/programme_explorer_supporter/ethics.svg"
                    title=''
                    level={''}
                    filters={ProgrammeExplorer.params}
                    metrics={[{ humanName: 'of HCPs agree this programme is balanced and unbiased', name: 'balanced_and_unbiased' }]}
                    symbol='%'
                    cardColor='white'
                    option='impact'
                    wave={wavesOptions && wavesOptions?.length > 0 ? false : true} 
                  />

                  {/*
                    <ProgrammeExplorerSupporterCard
                      key={'5'}
                      className='card cardcme height-impact'
                      image="/media/svg/programme_explorer_supporter/doctor.svg"
                      title=''
                      level={'5'}
                      filters={ProgrammeExplorer.params}
                      metrics={[{ humanName: 'of HCPs agree this programme improved their performance', name: 'performance_improving' }]}
                      symbol=''
                      cardColor='white'
                      option='impact'
                      wave={wavesOptions && wavesOptions?.length > 0 ? false : true}
                    />
                  */}
                </div>
              </div>

              {/*<div className='col-xl-3'>
                <div className='title-supporter'>Reach</div>
                <div className='subtitle-supporter'>How many HCPs in our community received the programme?</div>
                <div className='cardcmewrap'>
                  <ProgrammeExplorerSupporterCard
                    key={'6'}
                    className='card cardcme'
                    image='/media/svg/programme_explorer_supporter/laptop.svg'
                    title='Newsletter'
                    level={''}
                    filters={ProgrammeExplorer.params}
                    metrics={[{ humanName: 'No. of HCPs Reached', name: 'hpcs_reached_out_total' }]}
                    symbol=''
                    cardColor='white' />
                </div>
              </div>*/}
            </div><div className='row'>
                <div className='col-xl-12'>
                  <div className='title-supporter'>Engagement</div>
                  <div className='subtitle-supporter'>How many HCPs engaged with the programme?</div>
                  <div className='cardcmewrap'>
                    <ProgrammeExplorerSupporterCard
                      key={'7'}
                      className='card cardcme programme-explorer-card-orange'
                      image='/media/svg/programme_explorer_supporter/tablero.svg'
                      title=''
                      level={''}
                      filters={ProgrammeExplorer.params}
                      metrics={[{ humanName: 'No. of Times Clinical Takeaways Seen', name: 'page_views' }]}
                      symbol=''
                      cardColor='orange' />

                    <ProgrammeExplorerSupporterCard
                      key={'8'}
                      className='card cardcme'
                      image='/media/svg/programme_explorer_supporter/download.svg'
                      title=''
                      level={''}
                      filters={ProgrammeExplorer.params}
                      metrics={[{ humanName: 'Total Number of Downloads', name: 'slide_downloads' }]}
                      symbol=''
                      cardColor='white' />

                    <ProgrammeExplorerSupporterCard
                      key={'9'}
                      className='card cardcme'
                      image='/media/svg/programme_explorer_supporter/video-camera.svg'
                      title=''
                      level={''}
                      filters={ProgrammeExplorer.params}
                      metrics={[
                        { humanName: ' Video views on Website & YouTube', name:  'video_views' }
                      ]}
                      symbol=''
                      cardColor='white' />

                    <ProgrammeExplorerSupporterCard
                      key={'10'}
                      className={`card cardcme ${getProgrammesClassName(["podcast"])}`}
                      image='/media/svg/programme_explorer_supporter/mic.svg'
                      title=''
                      level={''}
                      filters={ProgrammeExplorer.params}
                      metrics={[{ humanName: 'Total Number of Podcast Listens', name: 'podcast_listens', type: 'podcast' }]}
                      symbol=''
                      cardColor='white'
                      wave={wavesOptions && wavesOptions?.length > 0 ? false : true} />

                    <ProgrammeExplorerSupporterCard
                      key={'12'}
                      className={`card cardcme ${getProgrammesClassName(["micro-learning", "e-learning"])}`}
                      image='/media/svg/programme_explorer_supporter/open-book.svg'
                      title=''
                      level={''}
                      filters={ProgrammeExplorer.params}
                      metrics={[{ humanName: 'Total Number of E-learning Visits', name: 'website_visits', type: 'elearning' }]}
                      symbol=''
                      cardColor='white'
                      elearning={ProgrammeExplorer.elearning}
                      />
                  </div>
                </div>
                <div className='col-xl-12'>
                  <div className='title-supporter'>Redes Sociales</div>
                  <div className='subtitle-supporter'>How many people interacted with the programme within our social media?</div>
                  <div className='cardcmewrap'>
                      <ProgrammeExplorerSupporterCard
                        key={'13'}
                        className='card cardcme'
                        image='/media/svg/programme_explorer_supporter/video-camera.svg'
                        title=''
                        level={''}
                        filters={ProgrammeExplorer.params}
                        metrics={[
                          { humanName: 'Video views on Social Media', name: 'social_media_video_views' },
                        ]}
                        symbol=''
                        cardColor='white' />

                      <ProgrammeExplorerSupporterCard
                        key={'14'}
                        className='card cardcme'
                        image='/media/svg/programme_explorer_supporter/heart.svg'
                        title=''
                        level={''}
                        filters={ProgrammeExplorer.params}
                        metrics={[{ humanName: 'Total Social Media Egagements', name: 'social_media_engagements' }]}
                        symbol=''
                        cardColor='white'
                        wave={wavesOptions && wavesOptions?.length > 0 ? false : true} />
                  </div>
                </div>
              </div>
              </>
          )
      }
    </>
  )
}

export { ProgrammeExplorerBase }
