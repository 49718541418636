import store from "app/store/store";
import { OptionsOrGroups } from "react-select";
import { connects } from "./data/connects";
import { metrics } from "./data/metrics";

const metricColors = [
   "#587D99",
   "#196ca3",
   "#0fa3b1",
   "#eddea4",
   "#ff9770",
   "#ff70a6",
   "#e9ff70",
   "#70d6ff",
   "#43aa8b",
   "#f94144",
   "#e9ff70",
   "#6f1d1b"
]

export const sourceOptions: OptionsOrGroups<any, any> = [
   {
      label: 'Organic Social',
      value: 'social'
   },
   {
      label: 'Email',
      value: 'email'
   },
   {
      label: 'Direct',
      value: 'direct'
   },
   {
      label: 'Organic Search',
      value: 'search'
   },
   {
      label: 'Referral',
      value: 'referral'
   }
]

export const getQueryString = function (field: any, url: string) {
   const windowLocationUrl = url ? url : "";
   const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
   const string = reg.exec(windowLocationUrl);
   return string ? string[1] : null;
};


export const getConnect = function (id: string) {
   let connect = connects.find(connect => connect.id === id);
   return connect?.name;
};


export const getMetric = function (id: string) {

   let metric;

   for (let [key, value] of Object.entries(metrics)) {
      if (key !== "" && value.id === id) {
         metric = value;
      }
   }

   return metric;
};


export const getLoadedMetricById = (metricId: any) => {

   const loadedMetrics = store.getState().metrics.metrics;

   for (let metric of loadedMetrics) {
      if (metric.id === metricId) {
         return metric.data;
      }
   }

   return null;

}

export const shortName = (name: string) => {
   if (name.length > 40) {
      return name.substring(0, 40) + "...";
   }

   return name;
}


export const getMetricColors = (index: number): string => {
   return metricColors[index] ?? "#000000";
}


export const replaceUnderscoresWithSpacesAndCapitalize = (inputString: string) => {
   let words
   // REPLACE UNDERSCORES WITH SPACES
   if (inputString.includes('_')) {
      words = inputString.replace(/_/g, ' ',).split(' ');
   } else {
      words = inputString.replace('-', ' ',).split(' ');
   }
 
   // FILTER OUT 'id' AND 'ids' FROM WORDS
   const filteredWords = words.filter((word) => !['id', 'ids'].includes(word.toLowerCase()));

   // CHECK IF THE NEW LAST WORD HAS AN 's' AND REMOVE IT
   if (words.length > 0) {
     const lastWord = words[words.length - 1];
     if (lastWord.endsWith('s')) {
       words[words.length - 1] = lastWord.slice(0, -1); // Remove the last character
     }
   }

   // CAPITALIZE FIRST LETTER OF EACH WORD
   const capitalizedWords = filteredWords.map((word) => {
     return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
   });
 
   // JOIN WORDS INTO A STRING
   return capitalizedWords.join(' ');
 };

 export const limitDecimals = (value: any) => {
   if (!isNaN(value) && value % 1 !== 0) {
     return parseFloat(value).toFixed(2);
   }
   return value;
 };


 export const formatNumber = (number : any) => {
   if (number === null || number === undefined) {
     return number;
   }
   let numberFormat = 0;
   // SEPARATE DECIMALS FROM INTEGER
   const parts = number.toString().split('.');
 
   // FORMAT INTEGER WITH COMMAS
   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
 
   // JOIN INTEGER AND DECIMALS
   numberFormat = parts.join('.');
 
   return numberFormat;
 };

