/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import ConfigRoutes from '../../../routing/ConfigRoutes'

import { KTSVG } from '../../../../_metronic/helpers';

import { CustomDateRangePicker, RangeDate } from '@components/form/CustomDateRangePicker';
import CustomSelect from '@components/form/CustomSelect';
import TempSelector from '@components/form/TempSelector';
import { DataReportFilterMode, useDataReport } from '../DataReportProvider';
import { MultiValue } from 'react-select';
import { ConnectService } from 'app/services/connect/ConnectService';
import Sticky from 'react-sticky-el'
import CustomSearchSelect from '@components/form/CustomSearchSelect';
import { CampaignService } from 'app/services/campaign/CampaignService';
import SnapshotModal from './snapshots/SnapshotModal';
import { TherapeuticAreaService } from 'app/services/therapeutic_area/TherapeuticAreaService';
import { LabelService } from 'app/services/label/LabelService';
import { ProgrammeService } from 'app/services/programme/ProgrammeService';
import { DataReportFilterParams, defaultFilterParams, DataReportFilters } from '../DataReportProvider';
import _ from 'lodash';
import { resetLoadedMetrics } from 'app/store/reducers/metric_reducer';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup } from 'react-bootstrap';

export const DataReportHeader: React.FC = () => {

    const dispatch = useDispatch();

    const location = useLocation();
    const [connects, setConnects] = useState([]);
    const [therapeuticAreas, setTherapeuticAreas] = useState([]);
    const [programmes, setProgramess] = useState([]);
    const [labels, setLabels] = useState([]);

    const [params, setParams] = useState<DataReportFilterParams>(defaultFilterParams);

    const showBotsStatus = useSelector((state: any) => state.bots.show_bots);

    const dataReport = useDataReport();

    const regionOptions = [
        { value: "Africa", label: 'Africa' },
        { value: "Asia", label: 'Asia' },
        { value: "Europe", label: 'Europe' },
        { value: "North America", label: 'North America' },
        { value: "Oceania", label: 'Oceania' },
        { value: "South America", label: 'South America' },
    ];


    useEffect(() => {
        getFiltersData();
    }, [])

    const getFiltersData = async () => {
        await getConnects();
        await getTherapeuticAreas();
        await getLabels();
    }

    const getLabels = async () => {
        let response = (await (new LabelService().search({ filter_order: [{ field: 'name', order: 'asc' }] }))).getResponseData();
        if (response.success) {
            let labels = response.data.map((element: any) => ({ value: element.id, label: element.name }))
            setLabels(labels);
        } else {
            console.error("Error getting available labels.")
        }
    }

    const getConnects = async () => {
        let response = (await (new ConnectService().search({ filter_order: [{ field: 'name', order: 'asc' }] }))).getResponseData();
        if (response.success) {
            // parse connect to select
            let connects = response.data.map((element: any) => ({ value: element.id, label: element.name }))
            setConnects(connects);
        } else {
            console.error("Error getting available connects.")
        }
    }

    const getTherapeuticAreas = async () => {
        let response = (await (new TherapeuticAreaService().search({ filter_order: [{ field: 'name', order: 'asc' }] }))).getResponseData();
        if (response.success) {
            let therapeuticAreas = response.data.map((element: any) => ({ value: element.id, label: element.name }))
            setTherapeuticAreas(therapeuticAreas);
        } else {
            console.error("Error getting available therapeutic areas.")
        }
    }

    const onChangeDate = (range: RangeDate) => {

        if (range.startDate && range.endDate) {
            updateFilters({
                between_dates: {
                    from: range.startDate,
                    to: range.endDate
                }
            });
        }

    }

    const onChangeTemp = (option: string) => {
        updateFilters({
            mode: option as DataReportFilterMode
        });
    }

    const onChangeLabels = (newValue: MultiValue<unknown>) => {
        let labelValues = newValue.map((value: any) => value.value);
        updateFilters({
            labels: labelValues
        })
    }

    const onChangeProgrammes = (newValue: MultiValue<unknown>) => {
        let programmeValues = newValue.map((value: any) => value.value);
        updateFilters({
            programmes: programmeValues
        })
    }

    const onChangeConnects = (newValue: MultiValue<unknown>) => {
        let connectValues = newValue.map((value: any) => value.value);
        updateFilters({
            connects: connectValues
        })
    }

    const onChangeTherapeuticAreas = (newValue: MultiValue<unknown>) => {
        let therapeuticAreasValues = newValue.map((value: any) => value.value);
        updateFilters({
            therapeutic_areas: therapeuticAreasValues
        })
    }

    const onChangeRegions = (newValue: MultiValue<unknown>) => {
        let regionValues = newValue.map((value: any) => value.value);
        updateFilters({
            regions: regionValues
        })
    }

    const onChangeCampaign = (newValue: any) => {
        if (newValue) {
            let campaignValues = newValue.map((value: any) => value.value);
            updateFilters({
                campaigns: campaignValues
            })
        }
    }

    const _changeIncludeBots = (ev: any) => {
        console.log(ev.target.checked);
        if(ev.target.checked){
            updateFilters({
                include_bots: true
            })
        }else{
            console.log("Se pone a false");
            updateFilters({
                include_bots: false
            })
        }
    }

    const onFixedToggle = (fixed: boolean) => {
        const header = document.getElementById('kt_header');

        if (fixed === true) {
            if (!header?.classList.contains('header-to-back')) {
                header?.classList.add('header-to-back');
            }
        }

        if (fixed === false) {
            if (header?.classList.contains('header-to-back')) {
                header?.classList.remove('header-to-back');
            }
        }
    }


    const onSearchCampaign = async (searchTerm: string) => {

        let response = (await (new CampaignService().search({
            filter_filters: {
                search: searchTerm
            }
        }))).getResponseData();

        if (response.success === true) {
            let responseOptions: MultiValue<unknown> = response.data.map(
                (element: any) => ({ value: element.code, label: `${element.code}-${element.wave}` })
            );
            return Promise.resolve(responseOptions);
        }

        return Promise.resolve([]);

    }

    const onSearchProgramme = async (searchTerm: string) => {

        let response = (await (new ProgrammeService().search({
            filter_filters: {
                name: searchTerm
            }
        }))).getResponseData();

        if (response.success === true) {
            let responseOptions: MultiValue<unknown> = response.data.map(
                (element: any) => ({ value: element.id, label: element.name })
            );
            return Promise.resolve(responseOptions);
        }

        return Promise.resolve([]);

    }

    const updateFilters = (filters: Partial<DataReportFilters>) => {

        let newObject = {
            ...params,
            filter_filters: {
                ...params.filter_filters,
                ...filters
            }
        }

        if (_.isEqual(params, newObject) === false) {
            setParams(newObject)
        }
    }

    const applyFilters = () => {
        dataReport.updateFilters(params.filter_filters);
    }

    const clearFilters = async () => {
        dispatch(resetLoadedMetrics());
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    return (

        <>
            <Sticky className='dashboard-header' mode='top' topOffset={0} stickyClassName="sticky sticky-header" onFixedToggle={onFixedToggle}>
                <div className='card mb-0 shadow-sm'>
                    <div className='card-body p-1'>
                        <div className='d-flex flex-wrap justify-content-start'>
                            <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                <div className='form-group'>
                                    <TempSelector defaultSelected={dataReport.params.filter_filters.mode} onValueSelected={onChangeTemp}></TempSelector>
                                </div>
                            </div>
                            <div className='p-2 flex-fill'>
                                <div className='form-group'>
                                    <CustomDateRangePicker defaultSelection={'this month'} onChangeDate={onChangeDate}></CustomDateRangePicker>
                                </div>
                            </div>
                            {/*<div className='p-2 flex-fill' style={{ minWidth: '250px' }}>*/}
                            {/*    <div className='form-group'>*/}
                            {/*        <CustomSelect options={connects} is_multi={true} placeholder="Select Connect Group" onChange={onChangeConnects}></CustomSelect>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className='p-2 flex-fill' style={{ minWidth: '250px' }}>*/}
                            {/*    <div className='form-group'>*/}
                            {/*        <CustomSelect options={regionOptions} is_multi={true} placeholder="Select GEO" onChange={onChangeRegions}></CustomSelect>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className='p-2 d-flex justify-content-end'>
                                <button className='btn btn-sm btn-apply' onClick={() => applyFilters()}>Apply</button>
                            </div>
                        </div>

                        <div className='d-flex flex-wrap justify-content-start '>
                            {/*
                                <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                    <div className='form-group'>
                                        <CustomSearchSelect options={[]} placeholder="Select Campaign" onChange={onChangeCampaign} onSearchInput={onSearchCampaign} multiple={true}></CustomSearchSelect>
                                    </div>
                                </div>
                             */}

                        </div>

                        {/*
                        <div className='d-flex flex-wrap justify-content-start '>
                            <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                <div className='form-group'>
                                    <CustomSelect options={therapeuticAreas} is_multi={true} placeholder="Select Therapeutic Area" onChange={onChangeTherapeuticAreas}></CustomSelect>
                                </div>
                            </div>
                            <div className='p-2 flex-fill'>
                                <div className='form-group'>
                                    <CustomSelect options={labels} is_multi={true} placeholder="Select Label" onChange={onChangeLabels}></CustomSelect>
                                </div>
                            </div>
                            <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                <div className='form-group'>
                                    <CustomSearchSelect options={[]} placeholder="Select Campaign" onChange={onChangeCampaign} onSearchInput={onSearchCampaign}></CustomSearchSelect>
                                </div>
                            </div>
                            <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                <div className='form-group'>
                                    <CustomSearchSelect options={programmes} placeholder="Select Programme type" onSearchInput={onSearchProgramme} onChange={onChangeProgrammes}></CustomSearchSelect>
                                </div>
                            </div>
                            

                            <div className='p-2 d-flex justify-content-end'>
                                <button className='btn btn-sm btn-apply' onClick={()=> applyFilters()}>Apply</button>
                            </div>
                        </div>
                        */}

                    </div>
                </div>

            </Sticky>

            <div className='d-flex overflow-auto h-55px ps-5 pt-5 pb-5 category-nav'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
                    <li className='nav-item'>
                        <Link
                            className={`nav-link text-active-primary me-6 ` +
                                (location.pathname.includes(ConfigRoutes.admin.dataReport.reach.base) && 'active')}
                            to={ConfigRoutes.admin.dataReport.reach.website.fullPath}
                        >
                            Reach
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={`nav-link text-active-primary me-6 ` +
                                (location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.base) && 'active')}
                            to={ConfigRoutes.admin.dataReport.engagement.website.fullPath}
                        >
                            Engagement
                        </Link>
                    </li>
                    {/*<li className='nav-item'>*/}
                    {/*    <Link*/}
                    {/*        className={`nav-link text-active-primary me-6 ` +*/}
                    {/*            (location.pathname.includes(ConfigRoutes.admin.dataReport.impact.base) && 'active')}*/}
                    {/*        to={ConfigRoutes.admin.dataReport.impact.website.fullPath}*/}
                    {/*    >*/}
                    {/*        Impact*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    <li className="ms-auto me-2 d-flex justify-content-end">

                        {/*<div className={`d-flex align-items-center me-2 form-check form-switch form-check-custom form-check-solid ${showBotsStatus ? '' : 'd-none'}`}>*/}
                        {/*    <input className="form-check-input h-20px w-30px" type="checkbox" checked={params.filter_filters.include_bots} onChange={_changeIncludeBots} value="" />*/}
                        {/*    <span className='ms-2 text-primary font-regular' style={{fontWeight: 500, fontSize: '0.925rem'}}>Include bots</span>*/}
                        {/*</div>*/}

                        <button type='button' className='btn btn-sm btn-link me-5 p-0' title={'Obtener informe'} onClick={() => { }}>
                            <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-2' />
                            Obtener informe
                        </button>

                        <button type='button' className='btn btn-sm btn-link p-0' title={'Clear filter'} onClick={() => clearFilters()}>
                            <KTSVG path='/media/icons/duotune/art/art004.svg' className='svg-icon-2' />
                            Clear
                        </button>
                    </li>
                </ul>
            </div>


            {location.pathname.includes(ConfigRoutes.admin.dataReport.reach.base) && (

                <div className='d-flex overflow-auto h-55px ps-5 pt-5 pb-5 category-nav'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.reach.website.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.reach.website.fullPath}
                            >
                                Website
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.reach.newsletter.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.reach.newsletter.fullPath}
                            >
                                Newsletter
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.reach.socialMedia.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.reach.socialMedia.fullPath}
                            >
                                Redes Sociales
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.reach.video.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.reach.video.fullPath}
                            >
                                Video
                            </Link>
                        </li>
                    </ul>
                </div>

            )}



            {location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.base) && (

                <div className='d-flex overflow-auto h-55px ps-5 pt-5 pb-5 category-nav'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.website.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.engagement.website.fullPath}
                            >
                                Website
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.newsletter.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.engagement.newsletter.fullPath}
                            >
                                Newsletter
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.socialMedia.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.engagement.socialMedia.fullPath}
                            >
                                Redes Sociales
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.podcast.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.engagement.podcast.fullPath}
                            >
                                Podcast
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.video.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.engagement.video.fullPath}
                            >
                                Video
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.slides.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.engagement.slides.fullPath}
                            >
                                Slides
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.elearning.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.engagement.elearning.fullPath}
                            >
                                E-learning
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.engagement.virtualMeeting.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.engagement.virtualMeeting.fullPath}
                            >
                                Virtual Meeting
                            </Link>
                        </li>
                    </ul>
                </div>
            )}


            {location.pathname.includes(ConfigRoutes.admin.dataReport.impact.base) && (

                <div className='d-flex overflow-auto h-55px ps-5 pt-5 pb-5 category-nav'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.impact.website.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.impact.website.fullPath}
                            >
                                Website
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.dataReport.impact.virtualMeeting.fullPath) && 'active')}
                                to={ConfigRoutes.admin.dataReport.impact.virtualMeeting.fullPath}
                            >
                                Virtual Meeting
                            </Link>
                        </li>
                    </ul>
                </div>
            )}

            <SnapshotModal show={dataReport.showSnapshotModal} />

        </>

    )
}


