import _ from 'lodash'
import MarketingWebsiteTable from '../../components/MarketingWebsiteTable'
import {useMarketing} from '../../MarketingProvider'
import { replaceUnderscoresWithSpacesAndCapitalize } from 'app/utils/utils';

const MarketingWebsiteWrapper = () => {
  
  const marketing = useMarketing();

  return (
    <div className='row'>
      {marketing.params.filter_filters.group !== '' ? 
        marketing.params.filter_filters.selected_names.length > 0 ? (
          <MarketingWebsiteTable />
       ) : (
        <h3 className={'text-muted text-center'}>Please select one or more {`${replaceUnderscoresWithSpacesAndCapitalize(marketing.params.filter_filters.group)}s`}</h3>
       ): (
        <h3 className={'text-muted text-center'}>Por favor seleccione un modo</h3>
      )}
    </div>
  )
}

export default MarketingWebsiteWrapper
