import { FC } from "react";
import { useLocation, Navigate } from "react-router";
import {ADMIN, SUPER_ADMIN, PARTNER} from "app/utils/data/roles";
import store from "app/store/store";

interface Props {
    roles: Array<string>;
    children: JSX.Element;
    redirectRoute?: string;
}

export const getUserRole = () => {

        let role = store.getState().auth.user?.role;

        console.log(role);

        if(role!== undefined && role !== null) {
            if(role == ADMIN || PARTNER || SUPER_ADMIN){
                return role;
            }
        
    }
    return PARTNER;
    
}

const RequireAuth: FC<Props> = ({ roles, children, redirectRoute }) => {

    const location = useLocation();

    if (redirectRoute === undefined || redirectRoute === null) {
        redirectRoute = '/error/unathorized';
    }

    return (roles.includes(getUserRole())  ? children : <Navigate to={redirectRoute} state={{ from: location }} replace />)

}

RequireAuth.defaultProps = {
    roles: [PARTNER],
}

export default RequireAuth;
