
import { Campaign } from 'app/models/programmes/Programme';
import moment from 'moment';
import { FC } from 'react'
import { TablesWidget9 } from '_metronic/partials/widgets';
import useProgrammeExplorerHeader from '../../hooks/ProgrammeExplorerHeaderHook';
import { useProgrammeExplorerSupporter } from '../../ProgrammeExplorerSupporterProvider';

const ProgrammeExplorerSupporterProjectInfoWrapper: FC = () => {

    const { programmes } = useProgrammeExplorerSupporter()

    return (
        <>
            {
                programmes.length > 0
                    ?
                    (

                        <div className='card mb-0 shadow-sm'>
                            <div className='card-body p-1'>
                                <TablesWidget9 className="card-primary" title="Users"
                                    subtitle="List of Cognito users accesing this application"
                                    data={programmes}
                                    pagination={false}
                                    columns={[
                                        { name: "Project Code", keyValue: 'projectCode', className: 'w-150px'},
                                        { name: "Name", sortable: false, keyValue: 'name', className: "min-w-100px", render(element: any): JSX.Element {
                                                return (
                                                  <>
                                                      {element.name.replace(/&#8211;/g, '&ndash;')}
                                                  </>

                                                )
                                            }},
                                        {
                                            name: "Landing Page", sortable: false, keyValue: 'path', className: "min-w-100px",
                                            render: (element: any) => {
                                                return (
                                                    <a target='_blank' href={`https://cor2ed.com/${element.connect?.path}/programmes/${element.path}`}>
                                                        {`${element.path}`}
                                                    </a>
                                                );
                                            }
                                        },
                                        {
                                            name: "Publication Date", sortable: false, keyValue: 'publishedAt', className: "min-w-250px",
                                            render: (element: any) => {
                                                let formatDate = moment(element.publishedAt.date);
                                                return (
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='text-muted me-2 fs-7 fw-bold'>{formatDate.format('YYYY-MM-DD HH:mm')}</span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        },
                                        {
                                            name: "Campaign UID", sortable: false, keyValue: 'waves', className: "min-w-150px",
                                            render: (element: any) => {

                                                if (element.campaigns.length == 0 ) {
                                                    return (
                                                        <span className="badge badge-light-danger mb-1">
                                                            NO CAMPAIGNS
                                                        </span>
                                                    )
                                                }

                                                return (
                                                  <>
                                                      {
                                                          element.campaigns.reduce((carry: any, element: Campaign) => {
                                                              if (!carry.includes(element.code + ": " + element.wave)) {
                                                                  carry.push(element.code + ": " + element.wave)
                                                              }
                                                              return carry
                                                          }, []).map((element: string) => {
                                                              return (
                                                                <>
                                                                    <span key={element} className="badge badge-light-primary mb-1">
                                                                        {element}
                                                                    </span>
                                                                    <br key={element + "-br"}></br>
                                                                </>

                                                              )
                                                          })
                                                      }
                                                  </>

                                                );
                                            }
                                        },
                                        {
                                            name: "1st Wave Date", sortable: false, keyValue: 'waves', className: "min-w-150px",
                                            render: (element: any) => {
                                                if (element.campaigns.length > 0) {
                                                    let campaigns = element.campaigns.sort((a: Campaign, b: Campaign) => {
                                                        return moment(a.data_date.date).diff(moment(b.data_date.date)) > 0 ? 1 : -1
                                                    })

                                                    let formatDate = moment(campaigns[0].data_date.date);
                                                    return (
                                                        <div className='d-flex flex-column w-100 me-2'>
                                                            <div className='d-flex flex-stack mb-2'>
                                                                <span className='text-muted me-2 fs-7 fw-bold'>{formatDate.format('DD-MM-YYYY')}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    return (<span className="badge badge-light-danger">No waves</span>);
                                                }
                                            }
                                        },
                                        {
                                            name: "last Wave Date", sortable: false, keyValue: 'waves', className: "min-w-150px",
                                            render: (element: any) => {
                                                if (element.campaigns.length > 0) {
                                                    let campaigns = element.campaigns.sort((a: Campaign, b: Campaign) => {
                                                        return moment(a.data_date.date).diff(moment(b.data_date.date)) < 0 ? 1 : -1
                                                    })

                                                    let formatDate = moment(campaigns[0].data_date.date);
                                                    return (
                                                        <div className='d-flex flex-column w-100 me-2'>
                                                            <div className='d-flex flex-stack mb-2'>
                                                                <span className='text-muted me-2 fs-7 fw-bold'>{formatDate.format('DD-MM-YYYY')}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    return (<span className="badge badge-light-danger">No waves</span>);
                                                }
                                            }
                                        },
                                    ]}
                                ></TablesWidget9>
                            </div>
                        </div>

                    )
                    :
                    (<h3 className={'text-muted text-center'}>Por favor elija un código de proyecto para visulizar los datos</h3>)
            }

        </>

    )
}

export { ProgrammeExplorerSupporterProjectInfoWrapper }
