const MarketingSocialMediaWrapper = () => {
    return (
    <div className='row'>
      {
          (<h3 className={'text-muted text-center'}>Por favor seleccione un modo</h3>)
      }

    </div>

  )
}

export default MarketingSocialMediaWrapper