import './ProgrammeExplorerCard.css'
import { ProgrammeExplorerSupporterCardHeader } from './ProgrammeExplorerSupporterCardHeader';
import { ProgrammeExplorerFilterParams } from 'app/pages/programmer_explorer/ProgrammeExplorerProvider';
import { MetricService } from 'app/services/metric/MetricService';
import { useEffect, useState } from 'react';
import WorkLoadComponent from '@components/workload_component/WorkLoadComponent';
import { useProgrammeExplorerSupporter } from '../ProgrammeExplorerSupporterProvider'

type Props = {
  className: string
  image: string
  title: string
  filters: ProgrammeExplorerFilterParams
  metrics: MetricsProps[]
  level: number | string
  symbol: string
  cardColor: string
  option?: string
  wave?: boolean
  elearning?: boolean
  podcast?: boolean
}

type DataCardProps = {
  symbol: string
  description: string
  cardColor?: string
  filters: ProgrammeExplorerFilterParams
  metric: string
  option: string | undefined
  showCard: Function,
  wave?: boolean,
  metricNumber?: number
  isElearning?: boolean | null
  elearning?: boolean
  isPodcast?: boolean | null
  podcast?: boolean
}

type MetricsProps = {
  humanName: string
  name: string
  type?: string
}



const ProgrammeExplorerSupporterCard: React.FC<Props> = ({ title, className, image, filters, metrics, level, symbol, cardColor, option, wave = true, elearning = false, podcast = false }) => {
  const ProgrammeExplorer = useProgrammeExplorerSupporter();
  const [show, setShow] = useState(true);
  const [viewCard, setViewCard] = useState(true);

  const showCard = (childData: boolean) => {
    // remove logic to show card
    //setShow(childData)
  }

  useEffect(() => {
    //setShow(true)
  }, [ProgrammeExplorer.params.filter_filters])

  return (

    <>{
      show === true ?
        <div className={className}>
          <ProgrammeExplorerSupporterCardHeader image={image} />
          {title !== '' && <span className="card-top-title fs-4">{title} </span>}

          {metrics.map((metric, index) => {
            return <ProgrammeExplorerSupporterBodyCard
              key={metric.name}
              metricNumber={index}
              showCard={showCard}
              metric={metric.name}
              description={metric.humanName}
              filters={filters}
              symbol={symbol}
              cardColor={cardColor}
              option={option}
              wave={wave}
              isElearning={metric.type === 'elearning' ? true : false}
              elearning={elearning}
              isPodcast={metric.type === 'podcast' ? true : false}
              podcast={elearning}>
            </ProgrammeExplorerSupporterBodyCard>
          })
          }
          {level !== '' && <div className={`cardlevel-${cardColor}`}>
            <div className="fs-8">
              Level
            </div>
            <div className="fs-5">
              {level}
            </div>
          </div>}
        </div> : null}
    </>
  )
}


const ProgrammeExplorerSupporterBodyCard: React.FC<DataCardProps> = ({ symbol, description, cardColor, filters, metric, option, showCard, wave, metricNumber = 1, isElearning = false, elearning = false, isPodcast = false, podcast = false }) => {

  const [total, setTotal] = useState<number | null>(null)
  const mainSeriesAbortController = new AbortController()

  const resetInitialMetricsValues = () => {
    setTotal(null)
  }

  // Async call to get total metric
  const getTotalMetric = (signal: AbortSignal) => {
    let { campaigns, programmes } = filters.filter_filters
    if (campaigns !== undefined && campaigns.length != 0) {
      programmes = []
    }
    let modifiedFilters = {
      filter_filters: {
        ...filters.filter_filters,
        programmes,
        therapeutic_areas: []
      }
    };
    // (new MetricService()).getMetricEndpointData(modifiedFilters, metric, 'total', signal).then((metricService: MetricService) => {
    //   let responseData = metricService.getResponseData();
    //   if (responseData !== undefined) {
    //     let random = Math.floor(Math.random() * 1000)
    //     setTotal(random)
    //   }
    // })
    let random = Math.floor(Math.random() * 1000)
    setTotal(random)
  }

  const getProgrammeOneByOne = async (signal: AbortSignal, programmes: any[]) => {
    let dataTotal = 0;
    let i = 0;
    const filter = JSON.parse(JSON.stringify(filters))
    for await (let programme of programmes) {
      delete filter.filter_filters.programmes
      let modifiedFilters = {
        filter_filters: {
          ...filter.filter_filters,
          'programmes': [programme],
          therapeutic_areas: []
        }
      };
      const metricService = await (await new MetricService()).getMetricEndpointData(modifiedFilters, metric, 'total', signal)
      let responseData = metricService.getResponseData();
      i++;
      dataTotal += responseData.data
    }
    return {data: dataTotal, message: 'OK', success: true};
  }

  useEffect(() => {
    resetInitialMetricsValues()
    getTotalMetric(mainSeriesAbortController.signal)

    return () => {
      mainSeriesAbortController.abort()
    }

  }, [filters])

  useEffect(() => {
    //viewEntyDataCard()
  }, [total])


  const viewEntyDataCard = () => {

    if (metric == "slide_downloads" && (total ==  null || total == 0)) {
      showCard(false)
    }

    if (metric == "video_views" && (total ==  null || total == 0)) {
      showCard(false)
    }

    if (metric == "podcast_listens" && (total ==  null || total == 0)) {
      showCard(false)
    }
  } 

  return (

    <WorkLoadComponent evaluateElement={total}>
      <div className={`cardpercentwrap-${cardColor}`}>
        <div className={metricNumber === 1 ? "card-top-title-multiple " : "card-top-title fs-4"}>
          {total ? `${new Intl.NumberFormat('en-US').format(total as number)}${symbol}` : '-'}
        </div>
        <div className={option === 'impact' ? "fs-9 impact-description" : metricNumber === 1 ? "fs-9" : "fs-5 no-wrap-text"}>
          {wave ? description : 'Data not available for filter selected'}
        </div>
      </div>
    </WorkLoadComponent>
  )

}

export { ProgrammeExplorerSupporterCard }
