/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers';
import { CustomDateRangePicker, RangeDate } from '@components/form/CustomDateRangePicker';
import CustomSelect from '@components/form/CustomSelect';
import { MultiValue, SingleValue } from 'react-select';
import Sticky from 'react-sticky-el'
import _ from 'lodash';
import { MarketingFilterParams, MarketingFilters, defaultFilterParams, useMarketing } from '../MarketingProvider';
import ConfigRoutes from 'app/routing/ConfigRoutes';
import moment from 'moment';
import InsightSelects from './InsightSelects';

const insightLevels = [ { value: 'overall', label: 'Total' }, { value: 'partial', label: 'Parcial' } /*{ value: 'therapeutic_areas', label: 'Therapeutic Area' }, { value: 'connects', label: 'Connect' }, { value: 'programmes', label: 'Programme' }, { value: 'formats', label: 'Format' }*/ ];

export const MarketingHeader: React.FC = () => {

    const location = useLocation();
    const [insight, setInsight] = useState('');
    const [programmes, setProgrammes] = useState([]);
    const [defaultDateRange, setDefaultDateRange] = useState<any>(null);
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const [params, setParams] = useState<MarketingFilterParams>(defaultFilterParams);

    const marketing = useMarketing();

    function getLabelContent(label: string): string {
        const index = label.indexOf('[');
        return index !== -1 ? label.substring(0, index).trim() : label;
      }

    const onChangeDate = (range: RangeDate) => {

        if (range.startDate && range.endDate) {
            updateFilters({
                between_dates: {
                    from: range.startDate,
                    to: range.endDate
                }
            });
        }

    }

    const onChangeInsight = (selected: SingleValue<unknown>) => {
        let groupSelected: any = selected;
        const filtersUpdated = groupSelected.value === 'overall'
            ? { group: groupSelected.value, selected_names: ['Overall'] , selected_group: groupSelected.value}
            : { group: groupSelected.value, selected_names: [] , selected_group: groupSelected.value };
        setProgrammes([]);
        updateFilters(filtersUpdated);
        setInsight(groupSelected.value);
    }

    const clearFilters = () => {
        if (insight == '') return;
        const filtersUpdated = insight === 'overall'
        ? { ...marketing.params.filter_filters , group: insight, selected_names: ['Overall'] , selected_group: insight}
        : { ...marketing.params.filter_filters , group: insight, selected_group: insight };
        marketing.updateFilters(filtersUpdated);
    }

    const onChangeFormats = (newValue: MultiValue<unknown>) => {
        let formats = newValue.map((value: any) => value.value);
        let formatsLabels = newValue.map((value: any) => value.label);
        marketing.updateFilters({
            connects: [],
            therapeutic_areas_ids: [],
            programmes: [],
            formats: formats,
            selected_names: formatsLabels
        })
    }

    const onChangeConnects = (newValue: MultiValue<unknown>) => {
        let connects = newValue.map((value: any) => value.value);
        let connectsLabels = newValue.map((value: any) => value.label);
        updateFilters({
            connects: connects,
            therapeutic_areas_ids: [],
            programmes: [],
            formats: [],
            selected_names: connectsLabels
        })
    }

    const onChangeTherapeuticAreas = (newValue: MultiValue<unknown>) => {;
        let therapeuticAreasValues = newValue.map((value: any) => value.value);
        let therapeuticAreasLabels = newValue.map((value: any) => value.label);
        updateFilters({
            therapeutic_areas_ids: therapeuticAreasValues,
            connects: [],
            programmes: [],
            formats: [],
            selected_names: therapeuticAreasLabels
        })
    }

    const onChangeProgrammes = (newValue: MultiValue<unknown>) => {;
        let programmesValues = newValue.map((value: any) => value.value);
        let programmesLabels = newValue.map((value: any) => getLabelContent(value.label));
        let publishedDate = newValue.map((value: any) => value.published).sort((a: any, b: any) => new Date(a).getTime() - new Date(b).getTime())[0];
        setDefaultDateRange({
            startDate: moment(publishedDate).toDate() || moment().startOf('month').toDate(),
            endDate: moment().endOf('day').toDate()
        })
        updateFilters({
            programmes: programmesValues,
            therapeutic_areas_ids: [],
            connects: [],
            formats: [],
            selected_names: programmesLabels,
            between_dates: {
                from: publishedDate?.slice(0, 10) || moment().startOf('month').format('YYYY-MM-DD'),
                to: moment().endOf('day').toISOString().slice(0, 10)
            }
        })
    }

    const onFixedToggle = (fixed: boolean) => {
        const header = document.getElementById('kt_header');

        if (fixed === true) {
            if (!header?.classList.contains('header-to-back')) {
                header?.classList.add('header-to-back');
            }
        }

        if (fixed === false) {
            if (header?.classList.contains('header-to-back')) {
                header?.classList.remove('header-to-back');
            }
        }
    }

    const updateFilters = (filters: Partial<MarketingFilters>) => {

        let newObject = {
            ...params,
            filter_filters: {
                ...params.filter_filters,
                ...filters
            }
        }

        if (_.isEqual(params, newObject) === false) {
            setParams(newObject)
        }
    }

    const applyFilters = () => {
        marketing.updateFilters(params.filter_filters);
    }

    return (

        <>
            <Sticky className='dashboard-header z-index-3000' mode='top' topOffset={0} stickyClassName="sticky sticky-header" onFixedToggle={onFixedToggle}>
                <div className='card mb-0 shadow-sm'>
                    <div className='card-body p-1'>
                        <div className='d-flex flex-wrap justify-content-start'>
                            <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                <div className='form-group'>
                                    <CustomSelect options={insightLevels} placeholder="Seleccione el modo" onChangeSingle={onChangeInsight}/>
                                </div>
                            </div>
                            <InsightSelects insightLevelSelected={insight} onChangeConnects={onChangeConnects} onChangeProgram={onChangeProgrammes} onChangeFormat={onChangeFormats}
                             onChangeTherapeuticArea={onChangeTherapeuticAreas} programs={programmes}  setPrograms={setProgrammes}/>
                            <div className='p-2 flex-fill' key={defaultDateRange?.startDate}>
                                <div className='form-group'>
                                    <CustomDateRangePicker defaultSelection={'custom'} customSelection={defaultDateRange} onChangeDate={onChangeDate}
                                    setIsOpen={setIsOpened}/>
                                </div>
                            </div>
                            <div className='p-2 d-flex justify-content-end' key={isOpened ? 'opened' : 'closed'}>
                                <button className='btn btn-sm btn-apply' disabled={isOpened} onClick={()=> applyFilters()}>Apply</button>
                            </div>
                        </div>

                    </div>
                </div>

            </Sticky>

            <div className='d-flex overflow-auto h-55px ps-5 pt-5 pb-5 category-nav  z-index-2000'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.marketing.newsletter.base) && 'active')}
                                to={ConfigRoutes.admin.marketing.newsletter.base}
                            >
                                Newsletter
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.marketing.website.base) && 'active')}
                                to={ConfigRoutes.admin.marketing.website.base} onClick={() => clearFilters()}
                            >
                                Website
                            </Link>
                        </li>
                        {/*<li className='nav-item'>*/}
                        {/*    <Link*/}
                        {/*        className={`nav-link text-active-primary me-6 ` +*/}
                        {/*            (location.pathname.includes(ConfigRoutes.admin.marketing.conversion.base) && 'active')}*/}
                        {/*        to={ConfigRoutes.admin.marketing.conversion.base}*/}
                        {/*    >*/}
                        {/*        Conversions*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.marketing.navigation.base) && 'active')}
                                to={ConfigRoutes.admin.marketing.navigation.base} onClick={() => clearFilters()}
                            >
                                Navigations
                            </Link>
                        </li>

                        {/*<li className='nav-item d-none'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.marketing.socialMedia.base) && 'active')}
                                to={ConfigRoutes.admin.marketing.socialMedia.base}
                            >
                                Social Media
                            </Link>
                        </li>*/}

                        <li className="ms-auto me-2">
                            <button type='button' className='btn btn-sm btn-link me-5 p-0' title={'Obtener informe'}
                                    onClick={() => {
                                    }}>
                                <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-2'/>
                                Obtener informe
                            </button>

                            <button type='button' className='btn btn-sm btn-link p-0' title={'Clear filter'}
                                    onClick={() => {
                                        window.location.reload();
                                    }}>
                                <KTSVG path='/media/icons/duotune/art/art004.svg' className='svg-icon-2'/>
                                Clear
                            </button>
                        </li>
                    </ul>
            </div>
            <hr></hr>
        </>

    )
}