/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'
import ConfigRoutes from '../../routing/ConfigRoutes'
import configRoutes from "../../routing/ConfigRoutes";
import { MarketingProvider } from './MarketingProvider';
import { MarketingHeader } from './components/MarketingHeader';
import MarketingNewsletterWrapper from './tabs/newsletter/MarketingNewsletterWrapper';
import MarketingWebsiteWrapper from './tabs/website/MarketingWebsiteWrapper';
import MarketingSocialMediaWrapper from './tabs/socialMedia/MarketingSocialMediaWrapper';
import MarketingConversionsWrapper from './tabs/conversions/MarketingConversionsWrapper';
import MarketingNavigationWrapper from './tabs/navigation/MarketingNavigationWrapper';

const breadcrumbs = [
  {
    title: 'Módulo',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Marketing',
    path: configRoutes.admin.marketing.base,
    isSeparator: true,
    isActive: true,
  },
]

const MarketingWrapper: FC = () => {

    
    return (
        <>
            <Routes>
                <Route
                    element={
                        <MarketingProvider>
                          <PageTitle breadcrumbs={breadcrumbs}>Marketing</PageTitle>
                            <MarketingHeader />
                            <Outlet />
                        </MarketingProvider>}
                >

                <Route path={ConfigRoutes.admin.marketing.newsletter.base} element={<MarketingNewsletterWrapper />} />

                <Route path={ConfigRoutes.admin.marketing.website.base} element={<MarketingWebsiteWrapper />} />

                <Route path={ConfigRoutes.admin.marketing.conversion.base} element={<MarketingConversionsWrapper />} />

                <Route path={ConfigRoutes.admin.marketing.navigation.base} element={<MarketingNavigationWrapper />} />

                <Route path={ConfigRoutes.admin.marketing.socialMedia.base} element={<MarketingSocialMediaWrapper />} />

                <Route index element={<Navigate to={ConfigRoutes.admin.marketing.newsletter.base} />} />

                </Route>
            </Routes>
        </>

    )
}

export { MarketingWrapper }