
import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageTitle } from '_metronic/layout/core'
import ConfigRoutes from '../../routing/ConfigRoutes'
import { IndivualMetricProvider } from './IndividualMetricProvider'
import { IndividualMetricHeader } from './components/IndividualMetricHeader'
import IndividualMetricsWrapperTable from './components/IndividualMetricWrapperTable'


const breadcrumbs = [
    {
        title: 'Módulo',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Individual Metrics',
        path: '/individual-metrics',
        isSeparator: true,
        isActive: true,
    },
]

const IndividualMetricWrapper: FC = () => {

    return (
        <>
            <Routes>

                <Route
                    element={
                        <IndivualMetricProvider>
                            <PageTitle breadcrumbs={breadcrumbs}>Individual Metrics</PageTitle>
                            <IndividualMetricHeader />
                            <IndividualMetricsWrapperTable />
                        </IndivualMetricProvider>}
                >
                    <Route index element={<Navigate to={ConfigRoutes.admin.individual_metrics.base} />} />
                </Route>

            </Routes>



        </>

    )
}

export { IndividualMetricWrapper }
