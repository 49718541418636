import React, { useEffect, useState } from "react";
import ProgrammeExplorerChart from "./ProgrammeExplorerChart";
import { PEData } from "@components/chart/types";
import './ProgrammeExplorerCard.css'
import ChartDirectionCounter from "@components/chart/ChartDirectionCounter";
import { ProgrammeExplorerCardHeader } from "./ProgrammeExplorerCardHeader";
import { ProgrammeExplorerFilteredCard } from "./ProgrammeExplorerFilteredCard";
import { ProgrammeExplorerFilterParams } from '../ProgrammeExplorerProvider';
import { MetricService } from "app/services/metric/MetricService";
import WorkLoadComponent from "@components/workload_component/WorkLoadComponent";
import { MetricTimeSerie, MetricTimeSerieObject } from "app/models/metrics/MetricTimeSerie";
import { getMetricColors } from "app/utils/utils";



type Props = {
    className: string
    image: string | Promise<Blob|null>
    title: string
    description?: string
    metric: string
    percent?: string,
    canOpen?: boolean,
    filters: ProgrammeExplorerFilterParams,
    showFilteredTimeSeries?: boolean,
    showFilteredSplitBySource?: boolean,
    showFilteredSplitByRegion?: boolean,
    showFilteredProgrammesRanking?: boolean,
    showFilteredTherapeuticAreaRanking?: boolean,
}



const ProgrammeExplorerCard: React.FC<Props> = ({ className, image, title, description, metric, percent, children, canOpen, filters, showFilteredTimeSeries, showFilteredSplitBySource, showFilteredSplitByRegion, showFilteredProgrammesRanking, showFilteredTherapeuticAreaRanking }) => {

    const [opened, setOpened] = useState(false)

    const [total, setTotal] = useState<number | null>(null)

    const [TAAverage, setTAAverage] = useState<number | null>(null)

    const [globalAverage, setGlobalAverage] = useState<number | null>(null)

    const [filterMatchMainSerie, setFilterMatchMainSerie] = useState<MetricTimeSerieObject | null>(null)
    const [sameTAMainSerie, setSameTAMainSerie] = useState<MetricTimeSerieObject | null>(null)

    const [mainSeries, setMainSeries] = useState<PEData[]>([])

    const [mainTimeseriesMode, setMainTimeseriesMode] = useState<'days' | 'weeks'>('weeks')

    const mainSeriesAbortController = new AbortController()
    const sameTASeriesAbortController = new AbortController()
    const taAverageAbortController = new AbortController()
    const globalAverageAbortController = new AbortController()
    const totalAbortController = new AbortController()

    useEffect(() => {
        resetInitialMetricsValues()

        getTotalMetric(mainSeriesAbortController.signal)
        //getTAAverage(sameTASeriesAbortController.signal)
        //getGlobalAverage(taAverageAbortController.signal)
        getMainSeries(globalAverageAbortController.signal)
        //getSameTAMainSeries(totalAbortController.signal)

        return () => {
            mainSeriesAbortController.abort()
            sameTASeriesAbortController.abort()
            taAverageAbortController.abort()
            globalAverageAbortController.abort()
            totalAbortController.abort()
        }

    }, [filters])

    useEffect(() => {
        if (filterMatchMainSerie === null) return
        combineMainSeries(filterMatchMainSerie, sameTAMainSerie)



    }, [filterMatchMainSerie, sameTAMainSerie])

    const resetInitialMetricsValues = () => {
        setTotal(null)
        setTAAverage(null)
        setGlobalAverage(null)
        setMainSeries([])
    }


    // Async call to get total metric
    const getTotalMetric = (signal: AbortSignal) => {
        // Programmes must be empty in totals when ins filtering by campaigns
        let { campaigns, programmes } = filters.filter_filters
        if (campaigns !== undefined && campaigns.length != 0) {
            programmes = []
        }

        let modifiedFilters = {
            filter_filters: {
                ...filters.filter_filters,
                programmes,
                therapeutic_areas: []
            }
        };

        // (new MetricService()).getMetricEndpointData(modifiedFilters, metric, 'total', signal).then((metricService: MetricService) => {
        //     let resonseData = metricService.getResponseData();
        //     if (resonseData.success == false) return
        // })

        let random = Math.floor(Math.random() * 100)
        setTotal(random)


    }


    // Async call to get TA Average
    const getTAAverage = (signal: AbortSignal) => {
        // Clone and modify filter removing specific programmes filtered
        let modifiedFilters = {
            filter_filters: {
                ...filters.filter_filters,
                programmes: [],
                campaigns: [],
                between_dates: undefined
            },
        };

        (new MetricService()).getMetricEndpointData(modifiedFilters, metric, 'average', signal).then((metricService: MetricService) => {
            let resonseData = metricService.getResponseData();
            if (resonseData.success == false) return
            setTAAverage(resonseData.data)
        })

    }

    // Async call to get Global Average
    const getGlobalAverage = (signal: AbortSignal) => {

        // Clone and modify filter removing all filters to get global average
        let modifiedFilters = {
            filter_filters: {}
        }

        new MetricService().getMetricEndpointData(modifiedFilters, metric, 'average', signal).then((metricService: MetricService) => {
            let resonseData = metricService.getResponseData();
            if (resonseData.success == false) return
            setGlobalAverage(resonseData.data)
        })

    }


    const getMainSeries = (signal: AbortSignal) => {
        let { campaigns, programmes } = filters.filter_filters
        if (campaigns !== undefined && campaigns.length != 0) {
            programmes = []
        }

        // Clone and modify filter removing all filters to get global average
        let modifiedFilters = {
            filter_filters: {
                ...filters.filter_filters,
                mode: mainTimeseriesMode,
                therapeutic_areas: [],
                programmes
            }
        }

        new MetricService().getMetricEndpointData(modifiedFilters, metric, 'timeseries', signal).then((metricService: MetricService) => {
            let resonseData = metricService.getResponseData();
            if (resonseData.success == false) return
            if (resonseData.data.length == 0) return
            let serie: MetricTimeSerieObject = {
                labels: resonseData.data.map((metric: MetricTimeSerie) => metric.date),
                values: resonseData.data.map((metric: MetricTimeSerie) => metric.value),
                color: getMetricColors(0)
            }

            setFilterMatchMainSerie(serie)
        })


    }


    const getSameTAMainSeries = (signal: AbortSignal) => {

        let modifiedFilters = {
            filter_filters: {
                ...filters.filter_filters,
                mode: mainTimeseriesMode,
                programmes: [],
                campaigns: [],
            }
        }

        new MetricService().getMetricEndpointData(modifiedFilters, metric, 'timeseries', signal).then((metricService: MetricService) => {
            let resonseData = metricService.getResponseData();
            if (resonseData.success == false) return
            if (resonseData.data.length == 0) return

            let serie: MetricTimeSerieObject = {
                labels: resonseData.data.map((metric: MetricTimeSerie) => metric.date),
                values: resonseData.data.map((metric: MetricTimeSerie) => metric.value),
                color: getMetricColors(1)
            }

            setSameTAMainSerie(serie)
        })

    }

    const combineMainSeries = (serie1?: MetricTimeSerieObject | null, serie2?: MetricTimeSerieObject | null) => {

        let newMainSeries: PEData[] = []

        serie2 &&
            newMainSeries.push({
                color: serie2.color,
                name: "Same Therapeutic Area",
                data: serie2.values,
                labels: serie2.labels
            })

        serie1 &&
            newMainSeries.push({
                color: serie1.color,
                name: "Current filtered data",
                data: serie1.values,
                labels: serie1.labels
            })

        setMainSeries(newMainSeries)

    }

    // Only toggle open if attribute canOpen is true
    const toggleOpened = (): void => {
        if (canOpen) {
            setOpened(!opened)
            setTimeout(() => {
                setMainSeries(Object.assign([], mainSeries))
            }, 200)
        }

    }

    const viewEntyDataCard = () => {
        let view = true

        if (metric == "slide_downloads" && (total ==  0 || total == null)) {
            view = false
        }

        if (metric == "video_views" && (total ==  0 || total == null)) {
            view = false
        }

        if (metric == "podcast_listens" && (total ==  0 || total == null)) {
            view = false
        }

        return view
    }

    return (
        <>

        { viewEntyDataCard() ? (

            <div className={`programme-explorer-card ${!opened ? 'col-xl-4 not-opened' : 'col-xl-12 opened'} `}>
                <div className={`card ${className} w-100 justify-content-start`}>

                    {
                        opened ?
                            (<ProgrammeExplorerFilteredCard
                                title={title + ' - Detailed view'}
                                total={total}
                                taAVG={(
                                    <WorkLoadComponent evaluateElement={total}>
                                        <ChartDirectionCounter basedOn={total} counter={TAAverage} title={'TA Average'}></ChartDirectionCounter>
                                        <ChartDirectionCounter basedOn={total} counter={globalAverage} title={'Global Average'}></ChartDirectionCounter>
                                    </WorkLoadComponent>
                                )}
                                image={image}
                                metric={metric}
                                showFilteredTimeSeries={showFilteredTimeSeries}
                                showRegionMetric={showFilteredSplitByRegion}
                                showTrafficSourceFilter={showFilteredSplitBySource}
                                showProgrammeRanking={showFilteredProgrammesRanking}
                                showTherapeuticAreaRanking={showFilteredTherapeuticAreaRanking}
                                defaultSeries={mainSeries}
                                defaultFilters={filters}
                                onClose={toggleOpened}
                            />)
                            : (
                                <>
                                    <div className='card-body d-flex flex-column align-items-center justify-content-start main-view-card-header'>
                                    <ProgrammeExplorerCardHeader image={image} title={title} description={description} counter={total}></ProgrammeExplorerCardHeader>
                                    {/*
                                        <div className={'d-flex flex-column w-100'}>
                                            <WorkLoadComponent evaluateElement={total}>
                                            <ChartDirectionCounter basedOn={total} counter={TAAverage} title={'TA Average'}></ChartDirectionCounter>
                                            <ChartDirectionCounter basedOn={total} counter={globalAverage} title={'Global Average'}></ChartDirectionCounter>
                                            </WorkLoadComponent>
                                        </div>
                                    */}
                                    </div>

                                    <div className='w-100 cursor-pointer main-view-card' onClick={toggleOpened}>
                                        <WorkLoadComponent evaluateElement={mainSeries} message={"Time series not available"}>
                                            <ProgrammeExplorerChart data={mainSeries} ></ProgrammeExplorerChart>
                                        </WorkLoadComponent>
                                    </div>
                                </>
                            )
                    }
                </div>
            </div>

        ) : <></> }

        </>
    )
}

ProgrammeExplorerCard.defaultProps = {
    canOpen: false,
    showFilteredTimeSeries: true,
    showFilteredSplitBySource: true,
    showFilteredSplitByRegion: true,
    showFilteredProgrammesRanking: true,
    showFilteredTherapeuticAreaRanking: true
}

export { ProgrammeExplorerCard }
