import { DataReportFilterMode } from 'app/pages/data_report/DataReportProvider'
import React, { useEffect, useState } from 'react'

type Props = {
    defaultSelected?: DataReportFilterMode,
    onValueSelected: (option: string) => void
}

type Option = {
    value: string,
    label: string
}

type Options = Array<Option>

const TempSelector: React.FC<Props> = ({ defaultSelected, onValueSelected }) => {

    const [currentSelected, setCurrentSelected] = useState<string | null>(null);
    const options: Options = [
        { value: 'years', label: 'Anual' },
        // { value: 'Q', label: 'Quarterly' },
        { value: 'months', label: 'Mensual' },
        { value: 'weeks', label: 'Semanal' },
        { value: 'days', label: 'Diario' },
        { value: 'custom', label: 'Total' },
    ]

    useEffect(() => {
        if (defaultSelected) {
            setCurrentSelected(defaultSelected);
        }
    }, [])

    const isActiveClass = (option: string): string => {
        if (currentSelected === option)
            return 'active';
        return '';
    }

    const selectOption = (option: string): void => {
        setCurrentSelected(option);
        onValueSelected(option);
    }

    return (
        <div className='btn-group btn-group-sm'>
            {
                options.map(
                    (option: Option, index: number) =>
                        <button 
                            key={index}
                            className={'btn btn-outline-primary ' + isActiveClass(option.value)}
                            onClick={() => { selectOption(option.value) }}
                        >
                            {option.label}
                        </button>
                )
            }
        </div>
    )
}

export default TempSelector;
