
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '_metronic/layout/core'
import ConfigRoutes from '../../routing/ConfigRoutes'
import { ProgrammeExplorerBase } from './components/ProgrammeExplorerBase'
import { ProgrammeExplorerHeader } from './components/ProgrammeExplorerHeader'
import { ProgrammeExplorerProvider } from './ProgrammeExplorerProvider'
import { ProgrammeExplorerEngagementWrapper } from './tabs/engagement/ProgrammeExplorerEngagementWrapper'
import { ProgrammeExplorerReachWrapper } from './tabs/reach/ProgrammeExplorerReachWrapper'
import { ProgrammeExplorerImpactWrapper } from './tabs/impact/ProgrammeExplorerImpactWrapper'
import { ProgrammeExplorerProjectInfoWrapper } from './tabs/project_info/ProgrammeExplorerProjectInfoWrapper'


const breadcrumbs = [
    {
        title: 'Módulo',
        path: '#',
        isSeparator: false,
        isActive: false,
    },
    {
        title: 'Programme exlorer for Programme Lead',
        path: 'programmeExplorer/engagement',
        isSeparator: true,
        isActive: true,
    },
]

const ProgrammeExplorerWrapper: FC = () => {

    return (
        <>
            <Routes>

                <Route
                    element={
                        <ProgrammeExplorerProvider>
                            <PageTitle breadcrumbs={breadcrumbs}>Explorador de Datos</PageTitle>
                            <ProgrammeExplorerHeader />
                            <Outlet />
                        </ProgrammeExplorerProvider>}
                >
                    <Route path={ConfigRoutes.admin.programme_explorer.reach.base} element={<ProgrammeExplorerReachWrapper />} />

                    <Route path={ConfigRoutes.admin.programme_explorer.engagement.base} element={<ProgrammeExplorerEngagementWrapper />} />

                    <Route path={ConfigRoutes.admin.programme_explorer.impact.base} element={<ProgrammeExplorerImpactWrapper />} />

                    <Route path={ConfigRoutes.admin.programme_explorer.project_info.base} element={<ProgrammeExplorerProjectInfoWrapper />} />

                    <Route index element={<Navigate to={ConfigRoutes.admin.programme_explorer.engagement.base} />} />

                </Route>

            </Routes>



        </>

    )
}

export { ProgrammeExplorerWrapper }
