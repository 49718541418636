/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'
import ConfigRoutes from '../../routing/ConfigRoutes'
import { DataReportHeader } from './components/DataReportHeader'
import { DataReportProvider, useDataReport } from './DataReportProvider'
import { EngagementWrapper } from './tabs/engagement/EngagementWrapper'
import { ImpactWrapper } from './tabs/impact/ImpactWrapper'
import { ReachWrapper } from './tabs/reach/ReachWrapper'
import configRoutes from "../../routing/ConfigRoutes";

const breadcrumbs = [
  {
    title: 'Módulo',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Reporte de Datos',
    path: configRoutes.admin.dataReport.reach.website.fullPath,
    isSeparator: true,
    isActive: true,
  },
]

const DataReportWrapper: FC = () => {

    
    return (
        <>
            <Routes>
                <Route
                    element={
                        <DataReportProvider>
                          <PageTitle breadcrumbs={breadcrumbs}>Reporte de Datos</PageTitle>
                            <DataReportHeader />
                            <Outlet />
                        </DataReportProvider>}
                >
                    <Route  path={ConfigRoutes.admin.dataReport.reach.principal} element={<ReachWrapper/> } />

                    <Route path={ConfigRoutes.admin.dataReport.engagement.principal} element={<EngagementWrapper />} />

                    <Route path={ConfigRoutes.admin.dataReport.impact.principal} element={<ImpactWrapper />} />

                    <Route index element={<Navigate to={ConfigRoutes.admin.dataReport.reach.website.fullPath} />} />

                </Route>

            </Routes>



        </>

    )
}

export { DataReportWrapper }
