/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import ConfigRoutes from '../../../routing/ConfigRoutes'
import { KTSVG } from '../../../../_metronic/helpers';
import CustomSelect from '@components/form/CustomSelect';
import Sticky from 'react-sticky-el'
import useProgrammeExplorerHeader from '../hooks/ProgrammeExplorerHeaderHook';
import { CustomDateRangePicker } from '@components/form/CustomDateRangePicker';
import moment from 'moment';

const ProgrammeExplorerHeader: React.FC = () => {
    const location = useLocation();

    const {
        target,
        isOpened,
        betweenDates,
        applyFilters,
        onFixedToggle,
        targetOptions,
        onChangeTarget,
        loading,
        projectCodes,
        onChangeProgrammeProjectCode,
        onChangeConnects,
        connects,
        programmes,
        getProgrammeValues,
        onChangeProgrammes,
        waveOptions,
        onChangeWaves,
        onChangeDate,
        setIsOpened,
    } = useProgrammeExplorerHeader();

    return (

        <>
            <Sticky className='dashboard-header' mode='top' topOffset={0} stickyClassName="sticky sticky-header" onFixedToggle={onFixedToggle}>
                <div className='card mb-0 shadow-sm'>
                    <div className='card-body p-1'>
                        <div className='d-flex flex-wrap justify-content-start'>
                            <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                <div className='form-group'>
                                    <CustomSelect options={targetOptions} onChangeSingle={onChangeTarget} placeholder="Selecciona modo..." />
                                </div>
                            </div>
                            {target === 'connect' && (
                                <div className='p-2 flex-fill'>
                                    <div className='form-group' id={'connect'}>
                                        <CustomSelect options={connects} is_multi={true}  placeholder="Select Connects" onChange={onChangeConnects} key={'connect'}/>
                                    </div>
                                </div>
                            )}
                            {target === 'project' && (
                                <>
                                <div className='p-2 flex-fill'>
                                    <div className='form-group'>
                                    <CustomSelect options={projectCodes} onChangeSingle={onChangeProgrammeProjectCode} placeholder="Select project code" />
                                    </div>
                                </div>
                                {programmes.length > 0 ? (
                                    <div className='d-flex flex-fill justify-content-start ' >
                                    <div className='p-2 flex-fill' style={{ minWidth: '250px' , maxWidth: 'inherit' }}>
                                        <div className='form-group'>
                                        <CustomSelect options={getProgrammeValues()} is_multi={true} placeholder="Select programme(s)" onChange={onChangeProgrammes} />
                                        </div>
                                    </div>
                                    </div>
                                ) : <></>}
                                {waveOptions.length > 0 ? (
                                    <div className='p-2 flex-fill' style={{ minWidth: '250px' }}>
                                        <div className='form-group'>
                                        <CustomSelect options={waveOptions} is_multi={true} placeholder="All waves" onChange={onChangeWaves}></CustomSelect>
                                        </div>
                                    </div>
                                ) : <></>}
                                </>
                            )}
                            <div className='p-2 flex-fill' key={betweenDates?.from + '-' + betweenDates?.to}>
                                <div className='form-group'>
                                <CustomDateRangePicker onChangeDate={onChangeDate} defaultSelection="custom" customSelection={{
                                    startDate: betweenDates?.from ? moment(betweenDates?.from).toDate() : moment().startOf('month').toDate(),
                                    endDate: betweenDates?.to ? moment(betweenDates?.to).toDate() : moment().endOf('day').toDate()
                                }} setIsOpen={setIsOpened}/>
                                </div>
                            </div>
                            <div className='p-2 d-flex justify-content-end'>
                                <button className='btn btn-sm btn-apply' disabled={(isOpened) || (loading) || (!betweenDates) || (!betweenDates && (target == undefined || target == null || target == '') && true)} onClick={() => applyFilters()}>Apply</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex overflow-auto h-55px ps-5 pt-5 pb-5 category-nav'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap w-100'>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.programme_explorer.reach.base) && 'active')}
                                to={ConfigRoutes.admin.programme_explorer.reach.base}
                            >
                                Reach
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.programme_explorer.engagement.base) && 'active')}
                                to={ConfigRoutes.admin.programme_explorer.engagement.base}
                            >
                                Engagement
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link
                                className={`nav-link text-active-primary me-6 ` +
                                    (location.pathname.includes(ConfigRoutes.admin.programme_explorer.impact.base) && 'active')}
                                to={ConfigRoutes.admin.programme_explorer.impact.base}
                            >
                                Impact
                            </Link>
                        </li>

                        { target == 'project' && (
                            <li className='nav-item'>
                                <Link
                                    className={`nav-link text-active-primary me-6 ` +
                                        (location.pathname.includes(ConfigRoutes.admin.programme_explorer.project_info.base) && 'active')}
                                    to={ConfigRoutes.admin.programme_explorer.project_info.base}
                                >
                                    Project Info
                                </Link>
                            </li>
                        )}

                        <li className="ms-auto me-2">

                            <button type='button' className='btn btn-sm btn-link me-5 p-0' title={'Obtener informe'}
                                    onClick={() => {
                                    }}>
                                <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-2'/>
                                Obtener informe
                            </button>

                            <button type='button' className='btn btn-sm btn-link p-0' title={'Clear filter'}
                                    onClick={() => {
                                        window.location.reload();
                                    }}>
                                <KTSVG path='/media/icons/duotune/art/art004.svg' className='svg-icon-2'/>
                                Clear
                            </button>
                        </li>
                    </ul>
                </div>
                <hr></hr>

            </Sticky>
        </>
    )
}

export {ProgrammeExplorerHeader}
